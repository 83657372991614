:root {
    --color1: #121249;
    --color2: #1C6787;
    --color3:#030229;
    --white: #FFFFFF;
    --rgb1: rgba(18, 18, 73, 0.5);
}
@media (max-width: 1024px) {
    .settingSidebar{
        display: block !important;
    }
    #hamberMenu, .noHeader{
        display: block;
    }
    .theme-white .navbar{
        background: var(--white) !important;
        box-shadow: 15px 9px 25px 0 rgb(0 0 0 / 10%);
     }
     .navbar{
        left: 0;
     }
     .main-content{
        padding-top: 130px;
     }
}
@media (max-width: 992px) {
   
}

@media (max-width: 768px) {
	.loginBgAbs{
        display: none;
    }
    #mobileCenterWrap{
        left : 50%;
        top : 50%;
        position : absolute;
        transform : translate(-50%, -50%);
    }
    .loginHeading1{
        font-size: 35px;
        line-height: 45px;
    }
    .loginHeading2{
        font-size: 19px;
        line-height: 25px;
    }
    .loginHeading3{
        font-size: 15px;
        line-height: 20px;
    }
    .main-content{
        padding-left: 15px;
        padding-right: 15px;
    }
    .customStepForm .wizard li{
        width: auto !important;
    }
    ul.libraryTabs{
        display: inline-block;
    }
}

@media (max-width: 520px) {
    .customRadio .selectgroup-item{
        margin-left: 2px;
    }
}

@media (max-width: 426px) {
    
}

@media (max-width: 320px) {
	input.verifyInputHeight{
        font-size: 2rem !important;
        height: 40px !important;
        padding: 5px 0px !important;
    }
}