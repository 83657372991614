:root {
    --color1: #121249;
    --color2: #1C6787;
    --color3: #030229;
    --color4: #21698C;
    --black: #000000;
    --white: #FFFFFF;
    --rgb1: rgba(18, 18, 73, 0.5);
}
body{
    font-family: 'Nunito', sans-serif;
    font-family: 'Inter', sans-serif;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 400;
    color: #6c757d
}
.btn {
    font-family: 'Nunito', sans-serif;
}
#preloader {
    background-color: var(--white);
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0px;
    top: 0px;
    z-index: 999;
}

.loading-center {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader .loader-outter {
    position: absolute;
    border: 4px solid var(--color2);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader .loader-inner {
    position: absolute;
    border: 4px solid var(--color2);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
.login{
    background-color:var(--white);
}
.loginBgAbs{
    background: url("../images/login-bg.svg") no-repeat center center;
    height: 100vh;
    background-size:cover;
}
.loginHeading1{
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: var(--color1);
    margin: 0;
}
.loginHeading2{
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color1);
    margin: 0;
}
.loginHeading3{
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color:var(--rgb1);
    margin: 0;
}
.loginTextArrow{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid var(--rgb1); 
    line-height: 0.1em; 
 } 
 .loginTextArrow span { 
     background:var(--white); 
     padding:0 10px; 
     color: var(--rgb1);
     font-size: 16px;
     font-weight: 600;
 }
 .auth-provider{
   border-radius: 20px;
   cursor: pointer;
   margin-bottom: 16px;
   box-sizing: border-box;
   border: 1px solid rgba(18, 18, 73, 0.15);
   text-align: center;
   background:var(--white);
   color: var(--color1);
 }
 .google-login {
   color: var(--color1);
   border-color: rgba(18, 18, 73, 0.15);
   font-weight: 500;
   font-size: 18px;
   line-height: 40px;
   padding: 0 21px;
 }
 input.verifyInputHeight{
    font-size:2rem !important;
    height: 60px !important;
    text-align: center;
    color: var(--color1) !important;
    font-weight: 500 !important;
 }
 .buttonNoBg{
    background: none !important;
    border: 2px solid var(--color2) !important;
    color: var(--color2)  !important;
 }
 .buttonNoBg:hover{
    color: var(--white) !important;
    border: none;
 }
 /********************
     Header
 ********************/
 #hamberMenu{
    display: none;
 }
 /*
 .theme-white .navbar{
    background: none !important;
    box-shadow: none;
 }
 .main-content{
    padding-top: 60px;
 }
 */
 .navbar.active{
    background: var(--white) !important;
    box-shadow: 15px 9px 25px 0 rgb(0 0 0 / 10%);
 }
 a.logoutSidebarWrap:hover{
    background: none !important;
 }
 .logutPara{
    position: relative;
    font-size: 12px;
    line-height: 35px;
    margin: 0px;
    color: #121249;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }
 /**************/
h1.mainHeadingH1{
    width: 100%;
    position: relative;
    font-size: 20px;
    line-height: 34px;
    margin: -30px 0px 30px 0px;
    color: var(--color1);
    font-weight: 500;
}
/************************************/
/*
.card, .main-sidebar, .navbar.active{
    box-shadow: none !important;
}
*/
h5.cardHeaderH5{
    font-size: 22px;
    font-weight: 800;
    line-height: 32px;
    color: var(--black);
}
h6.cardHeaderSubH6{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--color3);
    opacity: 0.7;
}
h4.cardHeaderH4{
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    color: var(--color3) !important;
}
.colorGray{
    color: var(--rgb1);
}
.colorBlue{
    color: var(--color2);
}
.dataTableHeader1 .table thead th{
   background: none !important;
   border: none !important;
}
/****************
    Step Form
****************/
.customStepForm{
    width: 100%;
    position: relative;
}
.customStepForm .wizard .steps a{
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    padding: 15px 0px;
}
.customStepForm .wizard>.steps .number{
    font-size: 16px;
}
.customStepForm fieldset{
    border-top: 1px solid #F0F0F2 !important;
    margin-top: 15px;
    padding-top: 30px !important;
}
.customStepForm .wizard .content .body, .customStepForm .wizard .content{
    padding: 0px;
    border: none;
}
.customStepForm .wizard>.steps .disabled a{
    background: none !important;
    color: #000000;
    border-bottom: 5px solid #f0f0f2;
}

.customStepForm .wizard .steps .current a, .customStepForm .wizard .steps .done a{
    background: none !important;
    color: var(--color2);
    border-bottom: 5px solid var(--color2);
    font-weight: 600;
}
.customStepForm .wizard>.actions>ul>li{
    margin: 20px 0px 20px 30px;
}
.customStepForm .wizard>.actions>ul>li a{
    color: var(--color2);
    box-shadow: 0px 15px 20px rgba(28, 103, 135, 0.1);
    border: 2px solid transparent;    
    background: 
    linear-gradient(to right, white, white), 
    linear-gradient(to right, #1C6787 , #9CECFB); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;  
    border-radius: 10px !important;  /* will have no effect */
}
.customStepForm .wizard>.actions>ul>li.disabled a{
    background: none;
    color: var(--rgb1);
    display: none !important;
}
.customStepForm .wizard>.actions>ul>li:first-child a{
    background: none;
    box-shadow:none;
}
/*
.customStepForm .wizard ul>li, .tabcontrol ul>li{
    padding: 0px 15px;
    font-size: 14px;
    line-height: 20px;
}
*/
.label{
    font-size: 14px !important;
    line-height: 14px !important;
    color: var(--color1) !important;
    font-weight: 500 !important;
    color:var(--black) !important
}
.customRadio{
    display: inline-block;
}
.customRadio .selectgroup-item{
    display: inline-block;
    margin-right: 15px !important;
    margin-bottom: 15px !important;
    background: var(--white);
}
.customRadio .selectgroup-item span{
    padding: 15px;
    height: auto !important;
    width: 90px !important;
    height: 90px !important;
    line-height: 55px !important;
    font-size: 40px;
    font-weight: 400;
    color: #8888a4;
}
.customRadio .selectgroup-item .selectgroup-button{
    border: 1px solid rgba(18, 18, 73, 0.1);
    border-radius: 9px;
    background: none !important;
}
.customRadio .selectgroup-input-radio:focus+.selectgroup-button, .selectgroup-input-radio:checked+.selectgroup-button{
    color: var(--color2);
    border-bottom: 5px solid var(--color2);
    box-shadow: 0px 4px 20px rgba(28, 103, 135, 0.1);
    font-weight: 500;
}
ul.steps2Tabs{
    margin: 0;
    padding: 0;
    display: inline;
}
ul.steps2Tabs li{
    text-align: center;
    width: auto !important;
}
ul.steps2Tabs li a{
    padding: 0px !important;
    background-color: none !important;
    box-shadow: none !important;
    color: #9d9db4 !important;
    border: 1px solid #F0F0F2;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
}
ul.steps2Tabs li a.active{
    background-color: none !important;
    background: none !important;
    color: var(--color1) !important;
}
ul.steps2Tabs li:first-child a{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
ul.steps2Tabs li:last-child a{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
}
#showSelectedOpt, #noOfQuestion, #showSelecetdRearrange, .round1, .round1Data{
    display: none;
}
.text-muted{
    color: var(--color1) !important;
}
.fixLblTextArea{
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
}
.form-control:disabled, .form-control[readonly]{
    background: #F2F2FB !important;
}
button.buttonRounded, a.buttonRounded{
    margin-top: 2px;
    border: 1px solid #F0F0F2;
    padding: 1px 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: var(--color2);
    display: block;
}
button.buttonRounded:hover, a.buttonRounded:hover{
    color: var(--color1) !important;
    border-color: #F0F0F2 !important;
}
.basicTable table th{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--rgb1);
    padding: 0px !important;
}
.basicTable table td{
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    color: var(--black);
    border-bottom: 1px solid #CCC;
    padding: 5px!important;
}
.editPencil i{
    color: var(--color2);
}
/***************
    No Header
 *************/
.noHeader{
    display: none;
}
button.bgButton, a.bgButton{
    background: var(--color4);
    border-radius: 10px;
    margin: 0px;
    padding: 3px 15px;
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
}
button.bgButton:hover, a.bgButton:hover, button.bgButton:active, a.bgButton:active, button.bgButton:focus, a.bgButton:focus{
    background: var(--color4) !important;
    color: var(--white);
}
.basicTable2{
    width: 100%;
    position: relative;
    border: 1px solid #e8ebed;
    border-radius: 10px;
    margin-bottom: 15px;
}
.basicTable2 .table{
    margin: 0px;
}
.basicTable2 .table th{
    background-color: rgba(33, 105, 140, 0.05) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--color3) !important;
}
.basicTable2 .table th:first-child{
    border-radius: 10px 10px 0px 0px !important;
} 
.basicTable2 .table th:last-child{
    border-top-right-radius: 10px !important;
}
.basicTable2 .table tr:nth-child(odd) {
    background-color: var(--white);
}
.basicTable2 .table tr:nth-child(even) {
    background-color: rgba(33, 105, 140, 0.05);
}/*
.basicTable2 .table tr{
    border: 1px solid rgba(0, 0, 0, 0.05);
} 
.basicTable2 .table tr:first-child{
    border-radius: 10px;
}*/
.basicTable2 .table td{
    border-top: 1px solid #e8ebed;
    border-bottom: 1px solid #e8ebed;
    margin: 1rem !important;
} 
/**********************************
            Calendar
***********************************/
.manageSchCal {
    width: 100%;
    position: relative;
    color: var(--color1) !important;
}
.manageSchCal .fc-toolbar.fc-header-toolbar{
    margin: 0px;
}
.manageSchCal .fc-center{
    width: 100%;
    display: block;
    padding: 14px;
}
.manageSchCal .fc-left{
    width: 50%;
    position: relative;
    background-color: var(--white);
    float: right;
    display: block;
    text-align: right !important;
    padding: 15px;
    border-radius: 0px 10px 0px 0px;
}
.manageSchCal .fc-left .fc-button-group{
    float: right;
}
.manageSchCal .fc-center{
    width: 50%;
    position: relative;
    background-color: var(--white);
    float: left;
    display: block;
    border-radius: 10px 0px 0px 0px;
}
.manageSchCal .fc-toolbar .fc-right{
    width: 100%;
    position: relative;
    float: right;
    text-align: right;
    margin-bottom: 30px;
}
.manageSchCal .fc-right .fc-button-group button{
    background: #f2f2fb !important;
    font-size: 14px;
    font-weight: 400;
    color: var(--color1);
    margin-left: 15px;
    border-radius: 10px;
    padding: 10px 15px;
}
.manageSchCal .fc-right .fc-button-group button.fc-state-active, .manageSchCal .fc-right .fc-button-group button:focus{
    background-color: var(--color2) !important;
    color: var(--white);
    outline: none;
}
.manageSchCal .fc-toolbar .fc-right .fc-button-group{
    float: right;
    text-align: right;
}
.manageSchCal .fc-toolbar .fc-left .fc-button-group button{
    padding: 0px;
}
.manageSchCal .fc-toolbar .fc-left .fc-button-group button:focus, .manageSchCal .fc-toolbar .fc-left .fc-button-group button:active{
    outline: none;
}
.manageSchCal  .fc-head{
    background: #f5f5f5 !important;
}
.manageSchCal .fc-view>table th{
    padding: 15px 0px;
}
.manageSchCal .fc-view-container{
    background-color: var(--white);
}
.manageSchCal td.fc-today{
    display: block !important;
    border-top:2px solid var(--color2) !important;
    background: transparent !important;
}
.dateCalendarMargin{
    margin-top: 40px;
}
#dateCalendar{
    width: 100%;
    position: relative;
    text-align: center;
    border: 1px solid #FAFAFB;
    border-radius: 5px;
}
#dateCalendar div{
    margin: 0px auto !important;
}
#dateCalendar table, #dateCalendar .datepicker-inline {
    position: relative;
    background-color: var(--white);
    width: 100%;
}
#dateCalendar .datepicker td, .datepicker th{
    width: 40px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
}
#dateCalendar .datepicker td.day{
    color: var(--color3);
    font-size: 12px;
    font-weight: 600;
}
#dateCalendar .datepicker td.old{
    color: var(--rgb1);
    font-size: 12px;
    font-weight: 600;
}
/*
#dateCalendar .datepicker td.today{
    background: #1C6787 !important;
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgba(96, 91, 255, 0.25);
    color: var(--white) !important;
}
*/
#dateCalendar .datepicker td.day.active, #dateCalendar .datepicker td.day:hover, #dateCalendar .datepicker td.today{
    background: #1C6787 !important;
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgba(96, 91, 255, 0.25);
    color: var(--white) !important;
}
button.customBtn, a.customBtn{
    background:var(--color2);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--white);
    padding: 5px 0px;
}
button.customBtn:hover, a.customBtn:hover, a.customBtn:focus, button.customBtn:focus{
    background:var(--color2) !important;
    color: var(--white);
}
.manageSchCal .fc-button{
    border: none;
    margin-left: 10px;
}
.manageSchCal .fc-icon-left-single-arrow:after{
    font-family: "Font Awesome 5 Free";
    content: "\f0d9";
    font-weight: 700;
    font-size: 24px;
    line-height: 1rem;
}
.manageSchCal .fc-icon-right-single-arrow:after{
    font-family: "Font Awesome 5 Free";
    content: "\f0da";
    font-weight: 700;
    font-size: 24px;
    line-height: 1rem;
}
/***************************
        Modal
***************************/
.customModal h5.modal-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color3);
}
.customModal button.close{
    background-color: #f2f2fb;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0px;
    outline: none;
    margin: -10px 0px 0px 0px;
}
.customModal button.close i{
    font-size: 20px;
    line-height: 40px;
    color: var(--color3);
}
.customModal button.close:hover, .customModal button.close:focus{
    border: none;
    outline: none;
}
button.customModalBtn, a.customModalBtn{
    background: var(--white);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 20px;
    color: var(--color2);
    padding: 10px 30px !important;
    border: 1px solid var(--rgb1);
}
button.customModalBtnBg, a.customModalBtnBg{
    background: var(--color2);
    color: var(--white);
}
button.customModalBtnOutline, a.customModalBtnOutline{
    outline: 5px solid #f4f8f9;
}
button.customModalBtn:hover, a.customModalBtn:hover, a.customModalBtn:focus, button.customModalBtn:focus{
    background:var(--color2) !important;
    color: var(--white);
}
ul.timeInlineList{
    margin: 0;
    padding: 0;
    line-height: 12px;
}
ul.timeInlineList li{
    list-style-type: none;
    display: inline;
    margin-right: 15px;
    font-size: 14px;
    line-height: 18px;
    color: var(--color3);
    font-weight: 400;
}
ul.timeInlineList li span{
    font-size: 12px;
    color:var(--rgb1)
}
a.linkText{
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    columns: var(--color2);
    text-decoration: none;
}
a.linkText:hover{
    text-decoration: none;
}

ul.libraryTabs{
    margin: 0;
    padding: 0;
    display: inline;
}
ul.libraryTabs li{
    text-align: center;
    width: auto !important;
}
ul.libraryTabs li a{
    padding: 5px !important;
    background-color: #f2f2fb !important;
    box-shadow: none !important;
    color: #9d9db4 !important;
    border: 1px solid #F0F0F2;
    border-radius: 10px !important;
    font-size: 14px;
    font-weight: 500;
    color: var(--rgb1);
}
ul.libraryTabs li a.active{
    background-color: var(--color2) !important;
    color: var(--white) !important;
}
ul.libraryTabs li:first-child{
    margin-left: 15px;
}
.accordionNumber{
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0px auto;
    background: #fafafb;
    text-align: center;
    border-radius: 30px;
    align-items: center;
}
.libraryAccordion .accordion .accordion-header{
    background-color: var(--white);
    box-shadow: none;
    border-radius: 10px;
    text-align: left;
    font-weight: 400;
    color: var(--color3);
    padding: 0px;
}
.libraryAccordion .accordion .accordion-body{
    margin-top:-10px;
    background-color: var(--white);
    border-radius: 0px 0px 10px 10px;
    text-align: left;
    padding: 15px 60px 0px 70px;
}
ul.libraryAccordionList{
    margin: 0px;
    padding: 0px;
    flex-direction: row !important;
    align-items: center !important;
}
ul.libraryAccordionList li{
    list-style-type: none;
    display: table-cell;
    font-size: 14px;
    line-height: 22px;
    vertical-align: middle;
    padding: 10px 15px;
}
ul.libraryAccordionList li:last-child{
    padding-left: 15px;
}
/*
.libraryAccordion .accordionRightArrow{
    transform: rotate(0deg);
}
.libraryAccordion .accordionRightArrow.collapsed{
    transform: rotate(90deg) !important;
}
*/
.libraryAccordion .accordionRightArrow[aria-expanded="false"]{
    transform: rotate(0deg) !important;
}
.libraryAccordion .accordionRightArrow[aria-expanded="true"]{
    transform: rotate(90deg) !important;
}


label.accordionLabel{
    font-size: 14px;
    font-weight: 500;
    color: var(--color2);
    line-height: 30px;
}
.table-row{
    cursor:pointer;
}
input.modalInputHeight{
    font-size: 2.5rem !important;
    height: 60px !important;
    text-align: center;
    color: var(--color1) !important;
    font-weight: 400 !important;
    color: var(--rgb1);
    background-color: #F7F7F8;
    border-radius: 10px;
 }
select.modalInputHeight{
    font-size: 2.5rem !important;
    height: 60px !important;
    text-align: center;
    color: var(--color1) !important;
    font-weight: 400 !important;
    color: var(--rgb1);
    background-color: #F7F7F8;
    border-radius: 10px;
 }
 label.modalLabelHeight{
    line-height: 50px !important;
    text-align: left;
 }
 .drillTimerBg{
    background: #F4F7F9;
    border: 1px solid rgba(18, 18, 73, 0.1);
    border-radius: 10px;
    color: var(--color3);
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
 }
 .questionLbl{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
 }
 .qutPreviousBtn{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color2);
 }
 .rightSidebarHeading{
    font-size: 16px;
    line-height: 18px;
    background: var(--color2);
    color: var(--white);
    font-weight: 500;
 }
 button.closeRightSidebar{
    font-size: 1.5rem;
    color: var(--white);
    font-weight: 400;
    outline: none;
    border:none;
 }
 p.para1{
    font-size: 14px;
    line-height: 18px;
    color: var(--color3);
 }

 
.ant-layout-sider-zero-width-trigger
{
    top:5px !important;
    z-index: 11 !important;
}
 
.noBoxShadow
{
  box-shadow: none !important;  
}
 